import { BaseElements, BaseObject, BaseService, BaseInjector } from 'ui-theme/js/base';
import { EmptyNodeList } from 'ui-theme/utility/utils/index';

class Texts extends BaseObject { }

class ClassList extends BaseObject {

}

class Selectors extends BaseObject {
    tocSections = ".toc__section"
    expandCloseAllBtn = ".allSectionsToggle"
    accordions = ".collapse"
    accordionToggles = ".accordion__toggle"
}

class Elements extends BaseElements<Selectors> {
    tocSections: NodeListOf<HTMLElement> = new EmptyNodeList();
    expandCloseAllBtn: HTMLElement;

    constructor(selectors: Selectors) {
        super(selectors, false);
    }

    setWrapper(wrapper: HTMLElement) {
        this.wrapper = wrapper;
        this.initElements()
    }

    // Loop over all elements and init them
    initElements() {
        this.initElementsByLoop(this.wrapper);
    }
}

interface TableOfContent extends BaseService<Selectors, Elements, ClassList, Texts> { }

@BaseInjector(Selectors, Elements, ClassList, Texts)
class TableOfContent {
    isOpen: boolean = false;
    constructor(protected readonly wrapper: any) { }

    initialize(): void {
        this.elements.setWrapper(this.wrapper);
        this.addEventListeners();
    }

    addEventListeners(): void {
        document.addEventListener("DOMContentLoaded", () => {
            this.createAccordion(this.elements.tocSections);
            this.expandCloseAllAccordions();
            this.scrollBackToSectionTop();
        })
    }

    createAccordion(sections: any): void {
        sections.forEach((section: HTMLElement, index: number) => {
            const header = section.querySelector("h2");
            if (!header || header?.nextElementSibling.classList.contains("toc__section")) return;
            const accordionLinkHTML = `
                        <a data-toggle="collapse" href="#collapse-${index}" role="button" aria-expanded="false" 
                           aria-controls="collapse-${index}" class="d-flex align-items-center to-section accordion__toggle collapsed">
                            <h2>${header.innerText}</h2>
                            <span class="icon-component">
                                <svg aria-hidden="true">
                                    <use xlink:href="/specs/products/app/releasedAssets/icons/icons-1c4da9ac2e2e916dcb782ae49f6d5981.spritemap.svg#arrows-down"></use>
                                </svg>
                            </span>
                        </a>
                    `;

            // Create a temporary div to insert the template as HTML
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = accordionLinkHTML.trim();

            // Replace <h2> with the new accordion link element
            const accordionLink = tempDiv.firstElementChild;
            header.replaceWith(accordionLink);

            // Create the collapsible content div
            const content = document.createElement("div");
            content.id = `collapse-${index}`;
            content.classList.add("collapse");

            // Move all elements after the accordion link into the collapsible div
            while (accordionLink.nextElementSibling) {
                content.appendChild(accordionLink.nextElementSibling);
            }

            section.appendChild(content);
        });
        this.showHideExpandAllBtn();
    }

    showHideExpandAllBtn(): void {
        const isAccordionExist = (this.wrapper.querySelectorAll(this.selectors.accordionToggles) || []).length > 0;
        if(!isAccordionExist) {
            this.elements.expandCloseAllBtn.style.display = "none";
        }
    }

    expandCloseAllAccordions(): void {
        const accordions = this.wrapper.querySelectorAll(this.selectors.accordions);
        this.elements.expandCloseAllBtn.addEventListener('click', () => {
            if(!this.isOpen) {
                $(accordions).collapse('show')
            }else {
                $(accordions).collapse('hide')
            }
            this.isOpen ? this.isOpen = false : this.isOpen = true;
        })
        
    }

    scrollBackToSectionTop(): void {
        const accordionToggles = this.wrapper.querySelectorAll(this.selectors.accordionToggles);
        accordionToggles.forEach((toggle: HTMLElement) => {
            toggle.addEventListener('click', () => {
                const top = getComputedStyle(toggle).top;
                toggle.style.scrollMarginTop = top;
                const collapseElement = toggle.nextElementSibling;
                if(!toggle.classList.contains('collapsed')) {
                    $(collapseElement).on('hidden.bs.collapse', function handleScroll(){
                        toggle.scrollIntoView({ behavior: "smooth", block: "start" });
                        collapseElement?.removeEventListener('hidden.bs.collapse', handleScroll);
                    })
                }
            })
        })
    }
}

export default TableOfContent;

export {
    Elements as TableOfContentElements,
    Selectors as TableOfContentSelectors,
    ClassList as TableOfContentClassList,
    Texts as TableOfContentTexts
}